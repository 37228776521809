import React from 'react';

import Layout from '../../components/Layout';
import CitiesSection from '../../components/CitiesSection/CitiesSection';

const CitiesPage = () => (
  <Layout>
    <CitiesSection />
  </Layout>
);

export default CitiesPage;
